// components/Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from "./assets/logo.png";

const Navbar = ({ onLanguageChange }) => {
  const [language, setLanguage] = useState("en");

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
    if (onLanguageChange) onLanguageChange(lang);
  };

  return (
    <nav style={styles.navbar}>
      <img src={logo} alt="Baku Bike Logo" style={styles.logo} />
      <div style={styles.navLinks}>
        <Link to="/" style={styles.navLink}>
          Home
        </Link>
        <Link to="/about" style={styles.navLink}>
          About Us
        </Link>
        <Link to="/privacy-policy" style={styles.navLink}>
          Privacy Policy
        </Link>
        <Link to="/terms" style={styles.navLink}>
          Terms & Conditions
        </Link>
        <Link to="/contact" style={styles.navLink}>
          Contact
        </Link>
        <Link to="/support" style={styles.navLink}>
          Support
        </Link>
      </div>
      <div style={styles.languageSelector}>
        <button
          style={styles.languageButton}
          onClick={() => handleLanguageChange("az")}
        >
          AZ
        </button>
        <button
          style={styles.languageButton}
          onClick={() => handleLanguageChange("en")}
        >
          EN
        </button>
        <button
          style={styles.languageButton}
          onClick={() => handleLanguageChange("ru")}
        >
          RU
        </button>
      </div>
    </nav>
  );
};

const styles = {
  navbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px",
    backgroundColor: "#ff0000", // Updated background color to match the app theme
    borderBottom: "1px solid #ffffff",
  },
  logo: {
    width: "40px",
  },
  navLinks: {
    display: "flex",
    gap: "20px",
  },
  navLink: {
    color: "#ffffff", // Ensures text is readable on the red background
    textDecoration: "none",
    fontSize: "16px",
    fontWeight: "bold",
    transition: "color 0.3s ease",
  },
  languageSelector: {
    display: "flex",
    gap: "10px",
  },
  languageButton: {
    backgroundColor: "#ffffff",
    color: "#ff0000",
    border: "none",
    padding: "5px 10px",
    cursor: "pointer",
    fontSize: "14px",
    borderRadius: "5px",
    transition: "background-color 0.3s ease, transform 0.3s ease",
  },
};

export default Navbar;
