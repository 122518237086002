// components/Contact.js
import React, { useState } from 'react';
import axios from 'axios';
import Navbar from './Navbar'; // Assuming you have a Navbar component
import Footer from './Footer'; // Assuming you have a Footer component
import { FaWhatsapp } from 'react-icons/fa'; // Import the WhatsApp icon from react-icons

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        source: 'Contact', // Add source property to indicate the page
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setSuccessMessage('');

        try {
            // Send the feedback data to your server endpoint
            const response = await axios.post('https://bakubike.duckdns.org/api/feedback', formData);
            setSuccessMessage(response.data.message || 'Thank you for reaching out! We will get back to you soon.');
            setFormData({ name: '', email: '', message: '', source: 'Contact' }); // Reset form
        } catch (error) {
            console.error('Error submitting feedback:', error);
            setError('Failed to submit feedback. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Navbar />
            <div style={styles.container}>
                <h1 style={styles.title}>Contact Us</h1>
                <div style={styles.content}>
                    <p style={styles.text}>
                        Have any questions, feedback, or suggestions? Fill out the form below, and we'll get back to you as soon as possible!
                    </p>
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
                    <form onSubmit={handleSubmit} style={styles.form}>
                        <label style={styles.label}>
                            Name:
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                                style={styles.input}
                            />
                        </label>
                        <label style={styles.label}>
                            Email:
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                style={styles.input}
                            />
                        </label>
                        <label style={styles.label}>
                            Message:
                            <textarea
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                required
                                style={styles.textarea}
                            />
                        </label>
                        <button type="submit" style={styles.button} disabled={loading}>
                            {loading ? 'Sending...' : 'Send Message'}
                        </button>
                    </form>
                </div>
            </div>
            <a
                href="https://wa.me/994105559666" // Replace with your WhatsApp number
                target="_blank"
                rel="noopener noreferrer"
                style={styles.whatsappButton}
            >
                <FaWhatsapp size={40} />
            </a>
            <Footer />
        </>
    );
};

const styles = {
    container: {
        fontFamily: "Helvetica, Arial, sans-serif",
        backgroundColor: "#f9f9f9",
        minHeight: "100vh",
        padding: "40px 20px",
        borderRadius: "10px",
        boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)",
        maxWidth: "800px",
        margin: "20px auto",
        textAlign: "center",
        color: "#333",
    },
    title: {
        fontSize: "32px",
        fontWeight: "bold",
        marginBottom: "20px",
        color: "#000",
    },
    content: {
        padding: "20px 40px",
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.05)",
        textAlign: "center",
    },
    text: {
        fontSize: "18px",
        lineHeight: "1.6",
        marginBottom: "20px",
        color: "#555",
    },
    form: {
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        marginTop: "20px",
        textAlign: "left",
    },
    label: {
        fontSize: "16px",
        marginBottom: "5px",
        color: "#333",
    },
    input: {
        padding: "10px",
        borderRadius: "5px",
        border: "1px solid #ddd",
        fontSize: "16px",
        width: "100%",
    },
    textarea: {
        padding: "10px",
        borderRadius: "5px",
        border: "1px solid #ddd",
        fontSize: "16px",
        height: "100px",
        width: "100%",
    },
    button: {
        padding: "10px 20px",
        backgroundColor: '#007aff',
        color: '#ffffff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: 'bold',
        transition: 'background-color 0.3s ease',
    },
    whatsappButton: {
        position: "fixed",
        bottom: "80px", // Position just above the footer
        right: "20px",
        color: "#25D366",
        backgroundColor: "#fff",
        borderRadius: "50%",
        padding: "10px",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
        cursor: "pointer",
        zIndex: 1000,
    },
};

export default Contact;
