// src/components/Overview.js
import React, { useState, useEffect } from "react";
import axios from "axios";

const Overview = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/api/auth/users`
        );
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  return (
    <div style={styles.container}>
      <h2 style={styles.header}>Overview</h2>
      {loading ? (
        <p>Loading users...</p>
      ) : (
        <div style={styles.userList}>
          <p>Total Users: {users.length}</p>
          <ul>
            {users.map((user) => (
              <li key={user.id} style={styles.userItem}>
                {user.name} ({user.email})
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    padding: "20px",
  },
  header: {
    fontSize: "24px",
    marginBottom: "20px",
    color: "#ff0000",
  },
  userList: {
    listStyleType: "none",
    padding: 0,
  },
  userItem: {
    padding: "10px",
    borderBottom: "1px solid #ddd",
  },
};

export default Overview;
