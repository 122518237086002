// components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import { FaInstagram } from 'react-icons/fa'; // Import Instagram icon from react-icons

const Footer = () => {
  return (
    <footer style={styles.footer}>
      <div style={styles.footerContent}>
        <div style={styles.footerLinksContainer}>
          <div style={styles.footerLinks}>
            <Link to="/" style={styles.footerLink}>
              Home
            </Link>
            <Link to="/about" style={styles.footerLink}>
              About Us
            </Link>
            <Link to="/privacy-policy" style={styles.footerLink}>
              Privacy Policy
            </Link>
            <Link to="/terms" style={styles.footerLink}>
              Terms & Conditions
            </Link>
            <Link to="/contact" style={styles.footerLink}>
              Contact
            </Link>
            <Link to="/support" style={styles.footerLink}>
              Support
            </Link>
          </div>
        </div>
        <div style={styles.contactDetails}>
          <p>
            Email: <a href="mailto:support@bakubike.az;xaqan.karimi@gmail.com" style={styles.link}>support@bakubike.az</a>
          </p>
          <p>
            Phone: <a href="tel:+994105559666" style={styles.link}>+994 10 555 96 66</a>
          </p>
          <p>
            <a href="https://www.instagram.com/bakubike.az" target="_blank" rel="noopener noreferrer" style={styles.link}>
              <FaInstagram style={styles.instagramIcon} /> @bakubike.az
            </a>
          </p>
        </div>
      </div>
      <p style={styles.copyright}>&copy; 2024 Baku Bike. All rights reserved.</p>
    </footer>
  );
};

const styles = {
  footer: {
    padding: "20px",
    textAlign: "center",
    backgroundColor: "#ff0000",
    color: "#fff",
  },
  footerContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    flexWrap: "wrap",
  },
  footerLinksContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "20px 0",
  },
  footerLinks: {
    display: "flex",
    gap: "20px",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  footerLink: {
    color: "#ffffff",
    textDecoration: "none",
    fontWeight: "bold",
    transition: "color 0.3s ease",
  },
  contactDetails: {
    position: "absolute",
    top: "0px",
    right: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    textAlign: "right",
    lineHeight: "0.001", // Reduced line height for closer spacing
  },
  link: {
    color: "#ffffff",
    textDecoration: "none",
    transition: "color 0.3s ease",
  },
  instagramIcon: {
    marginRight: "8px",
    verticalAlign: "middle",
  },
  copyright: {
    fontSize: "14px",
    marginTop: "10px",
  },
};

export default Footer;
