import React, { useState } from "react";
import axios from "axios";
import "./GenerateQR.css";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const GenerateQR = () => {
  const [bikeId, setBikeId] = useState("");
  const [qrCode, setQrCode] = useState("");

  const handleGenerateQR = async () => {
    try {
      const response = await axios.post(`${apiUrl}/api/auth/generate-qr`, {
        bikeId,
      });
      setQrCode(response.data.qrCode);
    } catch (error) {
      console.error("Error generating QR code:", error);
    }
  };

  return (
    <div className="generate-qr-container">
      <h1 className="title">Generate QR Code for Bike</h1>
      <input
        type="text"
        placeholder="Enter Bike ID"
        value={bikeId}
        onChange={(e) => setBikeId(e.target.value)}
        className="input"
      />
      <button onClick={handleGenerateQR} className="button">
        Generate QR Code
      </button>
      {qrCode && (
        <div className="qr-code-container">
          <h2 className="qr-code-title">QR Code for Bike {bikeId}</h2>
          <img
            src={qrCode}
            alt={`QR Code for bike ${bikeId}`}
            className="qr-code-image"
          />
        </div>
      )}
    </div>
  );
};

export default GenerateQR;
