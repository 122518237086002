import React from "react";
import { NavLink, Routes, Route, Navigate } from "react-router-dom";
import GenerateQR from "./GenerateQR";
import AdminPanel from "./Map";
import Overview from "./Overview"; // Assuming you have an Overview component

const Dashboard = ({ onLogout }) => {
  return (
    <div style={styles.container}>
      <div style={styles.sidebar}>
        <h2 style={styles.logo}>BakuBike Admin</h2>
        <nav style={styles.nav}>
          <ul style={styles.navList}>
            <li style={styles.navItem}>
              <NavLink
                to="/dashboard/overview"
                style={({ isActive }) =>
                  isActive
                    ? { ...styles.navLink, ...styles.activeLink }
                    : styles.navLink
                }
              >
                Overview
              </NavLink>
            </li>
            <li style={styles.navItem}>
              <NavLink
                to="/dashboard/generate-qr"
                style={({ isActive }) =>
                  isActive
                    ? { ...styles.navLink, ...styles.activeLink }
                    : styles.navLink
                }
              >
                Generate QR
              </NavLink>
            </li>
            <li style={styles.navItem}>
              <NavLink
                to="/dashboard/admin-panel"
                style={({ isActive }) =>
                  isActive
                    ? { ...styles.navLink, ...styles.activeLink }
                    : styles.navLink
                }
              >
                Map
              </NavLink>
            </li>
          </ul>
        </nav>
        <div style={styles.logoutContainer}>
          <button onClick={onLogout} style={styles.logoutButton}>
            Logout
          </button>
        </div>
      </div>
      <div style={styles.content}>
        <Routes>
          <Route path="overview" element={<Overview />} />
          <Route path="generate-qr" element={<GenerateQR />} />
          <Route path="admin-panel" element={<AdminPanel />} />
          <Route path="*" element={<Navigate to="/dashboard/overview" />} />
        </Routes>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    height: "100vh",
  },
  sidebar: {
    width: "250px",
    backgroundColor: "#ff0000",
    padding: "20px",
    color: "#fff",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between", // This ensures the logout button stays at the bottom
  },
  logo: {
    marginBottom: "30px",
    fontSize: "24px",
    fontWeight: "bold",
    textAlign: "center",
  },
  nav: {
    display: "flex",
    flexDirection: "column",
  },
  navList: {
    listStyle: "none",
    padding: 0,
    margin: 0,
  },
  navItem: {
    marginBottom: "10px",
  },
  navLink: {
    color: "#fff",
    textDecoration: "none",
    fontSize: "18px",
    padding: "10px",
    display: "block",
    borderRadius: "4px",
    transition: "background-color 0.3s ease",
  },
  activeLink: {
    backgroundColor: "#ffffff",
    color: "#ff0000",
  },
  logoutContainer: {
    marginTop: "auto", // Pushes the logout button to the bottom
  },
  logoutButton: {
    width: "100%",
    padding: "10px",
    fontSize: "18px",
    backgroundColor: "#fff",
    color: "#ff0000",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    textAlign: "center",
    transition: "background-color 0.3s ease",
  },
  logoutButtonHover: {
    backgroundColor: "#ff0000",
    color: "#fff",
  },
  content: {
    flex: 1,
    boxSizing: "border-box",
  },
};

export default Dashboard;
