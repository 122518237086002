// AboutUs.js
import React from 'react';
import Navbar from './Navbar'; // Assuming you have a Navbar component
import Footer from './Footer'; // Assuming you have a Footer component
import logo from './assets/logo.png'; // Use your logo if needed

const AboutUs = () => {
    return (
        <>
            <Navbar />
            <div style={styles.container}>
                <header style={styles.header}>
                    <img src={logo} alt="Baku Bike Logo" style={styles.logo} />
                    <h1 style={styles.title}>About Baku Bike</h1>
                </header>
                <div style={styles.content}>
                    <p style={styles.text}>
                        Baku Bike is your go-to bike rental service in Baku, offering a seamless, eco-friendly, and fun way to explore the city. Whether you’re commuting to work, sightseeing, or just enjoying a leisurely ride, we have a variety of bikes to suit your needs.
                    </p>
                    <p style={styles.text}>
                        Our user-friendly app allows you to find, reserve, and unlock bikes with just a few taps. With stations located throughout Baku, our service is designed to provide easy access to bikes wherever you are.
                    </p>
                    <h2 style={styles.subTitle}>Our Offerings</h2>
                    <p style={styles.text}>
                        We offer a range of bikes, including standard bikes, e-bikes, and family-friendly bikes, each available at competitive rates. Enjoy flexible rental options, from pay-as-you-go to monthly subscriptions that provide unlimited rides. Check out our latest promotions for special offers and discounts.
                    </p>
                    <h2 style={styles.subTitle}>Why Choose Baku Bike?</h2>
                    <ul style={styles.list}>
                        <li>Eco-Friendly Transportation: Reduce your carbon footprint while exploring the city.</li>
                        <li>Convenient and Affordable: Multiple rental options tailored to your needs and budget.</li>
                        <li>Seamless App Experience: Find, unlock, and ride with ease.</li>
                        <li>24/7 Customer Support: We’re here to help you at any time of the day.</li>
                    </ul>
                    <p style={styles.text}>
                        Join us in making Baku a greener, healthier, and more bike-friendly city. Ride with us today and enjoy the best of Baku on two wheels!
                    </p>
                </div>
            </div>
            <Footer />
        </>
    );
};

const styles = {
    container: {
        fontFamily: "Helvetica, Arial, sans-serif",
        backgroundColor: "#f9f9f9",
        color: "#333",
        minHeight: "100vh",
        padding: "40px 20px",
        maxWidth: "900px",
        margin: "20px auto",
        borderRadius: "10px",
        boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)",
    },
    header: {
        display: "flex",
        alignItems: "center",
        paddingBottom: "20px",
        borderBottom: "1px solid #ddd",
        marginBottom: "20px",
    },
    logo: {
        width: "50px",
        height: "50px",
        marginRight: "15px",
    },
    title: {
        fontSize: "32px",
        fontWeight: "bold",
        color: "#000",
    },
    content: {
        padding: "20px 0",
    },
    subTitle: {
        fontSize: "24px",
        fontWeight: "bold",
        marginTop: "20px",
        marginBottom: "10px",
        color: "#007aff",
    },
    text: {
        fontSize: "18px",
        lineHeight: "1.8",
        marginBottom: "20px",
        color: "#555",
    },
    list: {
        fontSize: "18px",
        lineHeight: "1.8",
        marginBottom: "20px",
        paddingLeft: "20px",
        color: "#555",
    },
};

export default AboutUs;
