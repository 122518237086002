import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import axios from "axios";
import "./Map.css"; // Import a CSS file for styling
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const containerStyle = {
  width: "100%",
  height: "100vh",
};

const center = {
  lat: 40.4093,
  lng: 49.8671,
};

const Map = () => {
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/auth/locations`);

        if (response.data && Array.isArray(response.data.locations)) {
          setLocations(response.data.locations);
        } else {
          console.error("Invalid data format", response.data);
          setLocations([]);
        }
      } catch (error) {
        console.error("Error fetching locations:", error);
        setLocations([]);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };

    fetchLocations();
    const interval = setInterval(fetchLocations, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="map-panel">
      <header className="map-header">
        <h1>Map - Baku Bike</h1>
      </header>

      <div className="map-container">
        {loading ? (
          <div className="loading-message">Loading map...</div>
        ) : (
          <LoadScript googleMapsApiKey="AIzaSyDyORYirT761zRHJOEf6w8ESnKxszVZH_Y">
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={12}
            >
              {locations.length > 0 ? (
                locations.map((location) => (
                  <Marker
                    key={location.id}
                    position={{
                      lat: location.latitude,
                      lng: location.longitude,
                    }}
                  />
                ))
              ) : (
                <div className="no-locations-message">
                  {/* No active rides at the moment. */}
                </div>
              )}
            </GoogleMap>
          </LoadScript>
        )}
      </div>
    </div>
  );
};

export default Map;
