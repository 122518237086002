import React, { useState } from 'react';
import axios from 'axios';
import Navbar from './Navbar'; // Assuming you have a Navbar component
import Footer from './Footer'; // Assuming you have a Footer component
import { FaWhatsapp } from 'react-icons/fa'; // Import WhatsApp icon from react-icons

const Support = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        source: 'Support', // Add source property to indicate the page
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setSuccessMessage('');

        try {
            // Send the feedback data to your server endpoint
            const response = await axios.post('https://bakubike.duckdns.org/api/feedback', formData);
            setSuccessMessage(response.data.message);
            setFormData({ name: '', email: '', message: '' });
        } catch (error) {
            console.error('Error submitting feedback:', error);
            setError('Failed to submit feedback. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Navbar />
            <div style={styles.container}>
                <h1 style={styles.header}>Support</h1>

                <section style={styles.section}>
                    <h2 style={styles.subHeader}>Frequently Asked Questions</h2>
                    <ul style={styles.faqList}>
                        <li>
                            <strong>How can I start a ride?</strong>
                            <p>To start a ride, scan the bike's QR code using the app.</p>
                        </li>
                        <li>
                            <strong>How do I add funds to my wallet?</strong>
                            <p>Go to the wallet section in the app, select your payment method, and add funds.</p>
                        </li>
                        <li>
                            <strong>Can I get a refund?</strong>
                            <p>Refunds are subject to our policy. Please contact support for more details.</p>
                        </li>
                    </ul>
                </section>

                <section style={styles.section}>
                    <h2 style={styles.subHeader}>Contact Information</h2>
                    <p>If you need help, you can reach us at:</p>
                    <ul style={styles.contactList}>
                        <li>Email: <a href="mailto:support@bakubike.az" style={styles.link}>support@bakubike.az</a></li>
                        <li>Phone: <a href="tel:+994105559666" style={styles.link}>+994 10 555 96 66</a></li>
                        <li>WhatsApp: <a href="https://wa.me/994105559666" target="_blank" rel="noopener noreferrer" style={styles.link}>
                            <FaWhatsapp style={styles.whatsappIcon} />Chat with us
                        </a></li>
                    </ul>
                </section>

                <section style={styles.section}>
                    <h2 style={styles.subHeader}>Feedback Form</h2>
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
                    <form onSubmit={handleSubmit} style={styles.form}>
                        <label style={styles.label}>
                            Name:
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                                style={styles.input}
                            />
                        </label>
                        <label style={styles.label}>
                            Email:
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                style={styles.input}
                            />
                        </label>
                        <label style={styles.label}>
                            Message:
                            <textarea
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                required
                                style={styles.textarea}
                            />
                        </label>
                        <button type="submit" style={styles.button} disabled={loading}>
                            {loading ? 'Submitting...' : 'Submit'}
                        </button>
                    </form>
                </section>
            </div>
            <Footer />
        </>
    );
};

const styles = {
    container: {
        fontFamily: "Helvetica, Arial, sans-serif",
        backgroundColor: "#f9f9f9",
        color: "#333",
        minHeight: "100vh",
        padding: "40px 20px",
        maxWidth: "900px",
        margin: "20px auto",
        borderRadius: "10px",
        boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)",
    },
    header: {
        fontSize: '36px',
        marginBottom: '20px',
        fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
        color: "#007aff",
        textAlign: "center",
    },
    subHeader: {
        fontSize: '24px',
        marginBottom: '10px',
        color: "#007aff",
    },
    section: {
        marginBottom: '20px',
        padding: "10px 20px",
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.05)",
    },
    faqList: {
        textAlign: 'left',
        listStyleType: 'none',
        padding: 0,
        margin: "10px 0",
        color: "#555",
    },
    contactList: {
        listStyleType: 'none',
        padding: 0,
        color: "#555",
        textAlign: "left",
    },
    label: {
        display: 'block',
        margin: '10px 0',
        fontSize: '18px',
        color: "#333",
    },
    input: {
        width: '100%',
        padding: '10px',
        margin: '5px 0',
        borderRadius: '5px',
        border: '1px solid #ccc',
        fontSize: '16px',
    },
    textarea: {
        width: '100%',
        height: '100px',
        padding: '10px',
        margin: '5px 0',
        borderRadius: '5px',
        border: '1px solid #ccc',
        fontSize: '16px',
    },
    button: {
        padding: '10px 20px',
        backgroundColor: '#007aff',
        color: '#ffffff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: 'bold',
        transition: 'background-color 0.3s ease',
    },
    link: {
        color: '#007aff',
        textDecoration: 'none',
        transition: 'color 0.3s ease',
    },
    whatsappIcon: {
        marginRight: "8px",
        verticalAlign: "middle",
    },
};

export default Support;
