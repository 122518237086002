import React, { useState } from "react";
import bcrypt from "bcryptjs";

const hashedPassword =
  "$2a$10$WWlqcQ1LZ5UKfWhEmSKOaeQ4L2ykLDmQsnlrYSlmnFKAel5ThAJNW"; // Make sure to keep this in sync with the hashed password

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    if (username === "admin" && bcrypt.compareSync(password, hashedPassword)) {
      onLogin(username, password);
    } else {
      alert("Invalid username or password");
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.innerContainer}>
        <h2 style={styles.header}>Admin Login</h2>
        <form onSubmit={handleSubmit} style={styles.form}>
          <div style={styles.formGroup}>
            <label style={styles.label}>Username:</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              style={styles.input}
            />
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label}>Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              style={styles.input}
            />
          </div>
          <button type="submit" style={styles.button}>
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#ff0000", // Red background color
    color: "#ffffff", // White text color
    animation: "fadeIn 2s ease-in-out", // Fade-in animation
  },
  innerContainer: {
    maxWidth: "400px",
    width: "90%", // Ensure it scales well on smaller screens
    padding: "20px",
    backgroundColor: "rgba(255, 255, 255, 0.9)", // Semi-transparent white background
    borderRadius: "10px",
    boxShadow: "0 0 20px rgba(0, 0, 0, 0.2)",
    backdropFilter: "blur(10px)", // Adds a subtle blur effect behind the container
    textAlign: "center",
    boxSizing: "border-box", // Ensure padding is included in the element's width and height
  },
  header: {
    color: "#ff0000",
    fontSize: "36px",
    marginBottom: "20px",
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
  },
  form: {
    color: "#ff0000",
    display: "flex",
    flexDirection: "column",
  },
  formGroup: {
    marginBottom: "15px",
  },
  label: {
    display: "block",
    marginBottom: "5px",
    fontSize: "18px",
  },
  input: {
    width: "100%", // Make input field full width
    padding: "10px",
    fontSize: "16px",
    borderRadius: "5px",
    border: "1px solid #ddd",
    boxSizing: "border-box", // Include padding and border in the element's width and height
  },
  button: {
    width: "100%", // Make button full width
    padding: "10px",
    fontSize: "18px",
    color: "#ffffff",
    backgroundColor: "#ff0000",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    transition: "background-color 0.3s ease, color 0.3s ease",
  },
};

export default Login;
