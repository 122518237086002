// PrivacyPolicy.js
import React from 'react';
import Navbar from './Navbar'; // Assuming you have a Navbar component
import Footer from './Footer'; // Assuming you have a Footer component

const PrivacyPolicy = () => {
    return (
        <>
            <Navbar />
            <div style={styles.container}>
                <h1 style={styles.title}>Privacy Policy</h1>
                <div style={styles.content}>
                    <p style={styles.text}>
                        Effective Date: 8/20/2024
                    </p>
                    <p style={styles.text}>
                        BakuBike ('we,' 'our,' 'us') is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our mobile application ('App').
                    </p>

                    <h2 style={styles.subTitle}>1. Information We Collect</h2>
                    <p style={styles.text}>
                        We may collect the following types of information:
                    </p>
                    <ul style={styles.list}>
                        <li style={styles.listItem}>- Personal Information: Name, Email Address, Phone Number, Physical Address, Payment Info, and other contact details.</li>
                        <li style={styles.listItem}>- Location Data: Precise and coarse location data to provide GPS tracking services.</li>
                        <li style={styles.listItem}>- Device Information: Device ID, operating system, and browser type.</li>
                        <li style={styles.listItem}>- Usage Data: Information on how you interact with the App, such as pages visited and features used.</li>
                        <li style={styles.listItem}>- Diagnostic Data: Crash logs, performance data, and other diagnostic information.</li>
                    </ul>

                    <h2 style={styles.subTitle}>2. How We Use Your Information</h2>
                    <p style={styles.text}>
                        We use the information we collect to:
                    </p>
                    <ul style={styles.list}>
                        <li style={styles.listItem}>- Operate and maintain the App.</li>
                        <li style={styles.listItem}>- Process your transactions and manage your account.</li>
                        <li style={styles.listItem}>- Provide customer support and respond to your inquiries.</li>
                        <li style={styles.listItem}>- Improve and personalize your experience with the App.</li>
                        <li style={styles.listItem}>- Analyze usage and performance data to enhance the App’s functionality.</li>
                        <li style={styles.listItem}>- Send you marketing communications, subject to your preferences.</li>
                    </ul>

                    <h2 style={styles.subTitle}>3. Sharing Your Information</h2>
                    <p style={styles.text}>
                        We may share your information with:
                    </p>
                    <ul style={styles.list}>
                        <li style={styles.listItem}>- Service Providers: Third-party companies that provide services on our behalf, such as payment processing and customer support.</li>
                        <li style={styles.listItem}>- Legal Authorities: When required by law or to protect our rights.</li>
                        <li style={styles.listItem}>- Business Transfers: In connection with a merger, acquisition, or sale of all or a portion of our assets.</li>
                    </ul>

                    <h2 style={styles.subTitle}>4. Tracking Technologies</h2>
                    <p style={styles.text}>
                        We use cookies, tracking pixels, and similar technologies to track user activity and preferences. You can control the use of cookies through your browser settings, but some features of the App may not function properly without them.
                    </p>

                    <h2 style={styles.subTitle}>5. Data Security</h2>
                    <p style={styles.text}>
                        We use a variety of security measures to protect your information. However, no method of transmission over the internet or electronic storage is 100% secure.
                    </p>

                    <h2 style={styles.subTitle}>6. User Rights</h2>
                    <p style={styles.text}>
                        You have the right to access, update, or delete your personal information. You can do this by contacting us at support@bakubike.az. You also have the right to opt-out of certain data processing activities.
                    </p>

                    <h2 style={styles.subTitle}>7. Changes to This Privacy Policy</h2>
                    <p style={styles.text}>
                        We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
                    </p>

                    <h2 style={styles.subTitle}>8. Contact Us</h2>
                    <p style={styles.text}>
                        If you have any questions about this Privacy Policy, please contact us at support@bakubike.az.
                    </p>
                </div>
            </div>
            <Footer />
        </>
    );
};

const styles = {
    container: {
        fontFamily: "Gilroy, 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
        backgroundColor: "#f9f9f9",
        minHeight: "100vh",
        padding: "40px 20px",
        borderRadius: "10px",
        boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)",
        maxWidth: "800px",
        margin: "20px auto",
        textAlign: "left",
    },
    title: {
        fontSize: "32px",
        fontWeight: "bold",
        textAlign: "center",
        marginBottom: "20px",
        color: "#ff0000",
    },
    content: {
        padding: "20px 40px",
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.05)",
        textAlign: "left",
    },
    text: {
        fontSize: "18px",
        lineHeight: "1.6",
        marginBottom: "10px",
        color: "#333",
        textAlign: "justify",
    },
    subTitle: {
        fontSize: "22px",
        fontWeight: "bold",
        marginTop: "20px",
        marginBottom: "10px",
        color: "#ff0000",
    },
    list: {
        paddingLeft: "20px",
        marginBottom: "20px",
    },
    listItem: {
        fontSize: "18px",
        marginBottom: "10px",
        color: "#333",
        textAlign: "justify",
    },
};

export default PrivacyPolicy;
