// Terms.js
import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';

const Terms = () => {
    return (
        <>
            <Navbar />
            <div style={styles.container}>
                <h1 style={styles.title}>Terms and Conditions</h1>
                <div style={styles.content}>
                    <h2 style={styles.subTitle}>1. GPS Tracking</h2>
                    <p style={styles.text}>
                        By using this App, you consent to your location being tracked via GPS during your ride. This tracking is essential for the operation of our services, including monitoring the location of rented bikes and ensuring the safety and security of our assets.
                    </p>

                    <h2 style={styles.subTitle}>2. Camera Access for QR Code Scanning</h2>
                    <p style={styles.text}>
                        The App requires access to your device's camera to scan QR codes. This functionality is necessary for renting and returning bikes. By accepting these terms, you consent to the use of your camera for this purpose.
                    </p>

                    <h2 style={styles.subTitle}>3. Permission to Withdraw Funds for Outstanding Debts</h2>
                    <p style={styles.text}>
                        In the event that you incur a debt related to our services, including but not limited to late fees, damage fees, or other charges, you agree that we may withdraw the outstanding amount from your registered bank card. By accepting these terms, you consent to this withdrawal.
                    </p>

                    <h2 style={styles.subTitle}>4. Covering Damages</h2>
                    <p style={styles.text}>
                        As a user of our services, you are responsible for any damages caused to the rented bike or other company property. You agree to cover the costs of repairs or replacements resulting from such damages. By accepting these terms, you acknowledge and accept this responsibility.
                    </p>

                    <h2 style={styles.subTitle}>5. User Registration and Authorization</h2>
                    <p style={styles.text}>
                        To use the App, you must register and create an account. You agree to provide accurate and complete information during registration and to keep this information updated. You are responsible for maintaining the confidentiality of your account credentials and are fully responsible for all activities that occur under your account.
                    </p>

                    <h2 style={styles.subTitle}>6. License to Use the App</h2>
                    <p style={styles.text}>
                        The Company grants you a non-exclusive, revocable license to use the App to locate and rent bikes. This license is subject to your compliance with this Agreement.
                    </p>

                    <h2 style={styles.subTitle}>7. Use of Personal Data</h2>
                    <p style={styles.text}>
                        By using the App, you consent to the collection, use, and sharing of your personal data as outlined in our Privacy Policy.
                    </p>

                    <h2 style={styles.subTitle}>8. Refund Policy</h2>
                    <p style={styles.text}>
                        Refunds for any payments made are subject to the Company’s refund policy, which can be found in the App. In cases of erroneous charges, please contact us at support@bakubike.az within the specified number of days.
                    </p>

                    <h2 style={styles.subTitle}>9. Termination of Agreement</h2>
                    <p style={styles.text}>
                        The Company reserves the right to terminate or suspend your account if you violate any terms of this Agreement or engage in conduct that the Company determines to be inappropriate or harmful to others.
                    </p>

                    <h2 style={styles.subTitle}>10. Limitation of Liability</h2>
                    <p style={styles.text}>
                        The Company is not liable for any indirect, incidental, special, or consequential damages arising out of or in connection with the use of the App, including but not limited to damages for loss of profits, use, data, or other intangibles.
                    </p>

                    <h2 style={styles.subTitle}>11. Dispute Resolution</h2>
                    <p style={styles.text}>
                        Any disputes arising out of or relating to this Agreement will be governed by the laws of the Republic of Azerbaijan and will be resolved through binding arbitration in Baku, Azerbaijan.
                    </p>

                    <h2 style={styles.subTitle}>12. Changes to the Agreement</h2>
                    <p style={styles.text}>
                        The Company may modify this Agreement at any time by posting the revised terms on the App. Your continued use of the App after any such changes constitutes your acceptance of the new terms.
                    </p>

                    <h2 style={styles.subTitle}>13. Contact Information</h2>
                    <p style={styles.text}>
                        If you have any questions about this Agreement, please contact us at support@bakubike.az.
                    </p>
                </div>
            </div>
            <Footer />
        </>
    );
};

const styles = {
    container: {
        fontFamily: "Gilroy, 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
        backgroundColor: "#f9f9f9",
        minHeight: "100vh",
        padding: "40px 20px",
        borderRadius: "10px",
        boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)",
        maxWidth: "800px",
        margin: "20px auto",
        textAlign: "left",
    },
    title: {
        fontSize: "32px",
        fontWeight: "bold",
        textAlign: "center",
        marginBottom: "20px",
        color: "#ff0000",
    },
    content: {
        padding: "20px 40px", // Adjusted padding for better alignment
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.05)",
        textAlign: "left", // Ensures left alignment of text
    },
    text: {
        fontSize: "18px",
        lineHeight: "1.6",
        marginBottom: "10px",
        color: "#333",
        textAlign: "justify", // Aligns text evenly on both sides
    },
    subTitle: {
        fontSize: "22px",
        fontWeight: "bold",
        marginTop: "20px",
        marginBottom: "10px",
        color: "#ff0000",
    },
};

export default Terms;
